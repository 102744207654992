import { FC, useContext, useEffect, useState } from "react";
import { StyledCommunicationWrapper } from "./styles";
import UnitDetails from "../UnitDetails";
import MessageNotification from "../common/Message";
import {
  PaymentDetails,
  TransactionPaymentStatusDetails,
  UnitDetailsInterface,
} from "../../@types";
import { useMessagesRetriever } from "../../hooks/generalHooks/useMessagesOnSocketStatus";
import {
  TransactionStatusDetails,
  UnitIdContext,
} from "../Containers/UnitsContainer";
import LoadingComponent from "../common/Loading";
import {
  hasTransactionStatus,
  hasValidFlowStatus,
} from "../../utils/unitUtils";
import { INVALID_FLOW_STATUSES } from "../../constants";
import Footer from "../common/Footer";
import PaymentProcessorWrapper from "../paymentProcessor/PaymentProcessorWrapper";

interface Props {
  unitDetails: UnitDetailsInterface;
  handleNonce: (nonce: PaymentDetails) => void;
  paymentLink: string;
  userId: string;
  showGeneralLoading: boolean;
  demo?: boolean;
}

const PoolTableCommunication: FC<Props> = ({
  unitDetails,
  handleNonce,
  paymentLink,
  userId,
  showGeneralLoading,
  demo = false,
}) => {
  const CATEGORY_UNIT = unitDetails?.hostType?.name;
  const POOL_PAYMENT_VALUE = 1;
  const unitId = useContext(UnitIdContext);
  const transactionPaymentStatusDetails =
    useContext<TransactionPaymentStatusDetails>(TransactionStatusDetails);
  const [isPayButtonPressed, setIsPayButtonPressed] = useState(false);

  const [
    isDisplayedPaymentButton,
    isDisplayedLoading,
    messageType,
    messageTitle,
    messageBody,
  ] = useMessagesRetriever(unitDetails);

  const [paymentValue, setPaymentValue] = useState(
    unitDetails?.defaultTransactionAmount
      ? `${unitDetails.defaultTransactionAmount}`
      : "1.0"
  );

  const onChangePaymentValueHandler = (value: string) => {
    setPaymentValue(value);
  };

  useEffect(() => {
    if (unitDetails && unitDetails.creditType === "HOST_SING_VAL") {
      unitDetails.defaultTransactionAmount &&
        setPaymentValue(unitDetails.defaultTransactionAmount.toString());
    }
  }, [unitDetails, unitId, paymentValue]);

  const onClickPayBtnHandler = () => {
    if (
      !!paymentValue &&
      parseFloat(paymentValue) <= unitDetails.hostType.maxTransactionAmount
    ) {
      handleNonce({
        unitId,
        nonce: "",
        amount: parseFloat(paymentValue),
        currency: "GBP",
        paymentProvider: null,
        paymentProcessor: unitDetails.paymentProcessor,
      });
      setIsPayButtonPressed(true);
    }
  };

  const shouldShowPaymentProcessor =
    unitDetails.status === "READY" &&
    !userId &&
    !showGeneralLoading &&
    !hasTransactionStatus(transactionPaymentStatusDetails.transactionStatus) &&
    hasValidFlowStatus(unitDetails.flowStatus);

  const shouldShowInvalidFlowMessage =
    !userId &&
    !showGeneralLoading &&
    INVALID_FLOW_STATUSES.indexOf(unitDetails.flowStatus) !== -1;

  const shouldShowTransactionStatusMessage =
    transactionPaymentStatusDetails.transactionStatus !== "" &&
    !showGeneralLoading &&
    hasTransactionStatus(transactionPaymentStatusDetails.transactionStatus);

  return (
    <StyledCommunicationWrapper>
      <UnitDetails
        categoryUnit={CATEGORY_UNIT}
        unitDetails={unitDetails}
        paymentValuePerUnit={POOL_PAYMENT_VALUE}
        isVending={false}
        transactionPaymentStatusDetails={transactionPaymentStatusDetails}
        photoUrl={unitDetails?.photoUrl ?? null}
        isPayButtonPressed={isPayButtonPressed}
        userId={userId}
        onChangePaymentValueHandler={onChangePaymentValueHandler}
        isLoading={showGeneralLoading}
        paymentLink={paymentLink}
      />
      <div className="payment-wrapper">
        {shouldShowPaymentProcessor && (
          <PaymentProcessorWrapper
            unitDetails={unitDetails}
            isDisplayedPaymentButton={!!isDisplayedPaymentButton}
            isDisplayedLoading={!!isDisplayedLoading}
            paymentValue={paymentValue}
            paymentLink={paymentLink}
            demo={demo}
            onClickPayBtnHandler={onClickPayBtnHandler}
            handleNonce={handleNonce}
            POOL_PAYMENT_VALUE={POOL_PAYMENT_VALUE}
            CATEGORY_UNIT={CATEGORY_UNIT}
          />
        )}

        {shouldShowInvalidFlowMessage && (
          <MessageNotification
            messageType={messageType as string}
            messageTitle={messageTitle as string}
            messageBody={messageBody as string}
          />
        )}

        {shouldShowTransactionStatusMessage && (
          <MessageNotification
            messageType={transactionPaymentStatusDetails.transactionStatus}
            messageTitle={
              transactionPaymentStatusDetails.transactionStatusTitle
            }
            messageBody={transactionPaymentStatusDetails.transactionStatusBody}
          />
        )}

        {showGeneralLoading && (
          <LoadingComponent displayLoadingMessage={false} />
        )}
      </div>
      <div style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}>
        <Footer />
      </div>
    </StyledCommunicationWrapper>
  );
};

export default PoolTableCommunication;
