export type PaymentProcessor = "CASHFLOWS" | "VIVA" | "SQUARE";
export interface PaymentDetails {
  unitId: string;
  nonce: string;
  amount: number;
  currency: string;
  selectionRefs?: string[];
  specialButtonsPressed?: string[];
  paymentProvider: string | null;
  paymentProcessor: PaymentProcessor | "";
}

export interface StatusPayment {
  msg: string;
}

export type FlowStatus =
  | "CONTACTLESS_UNAVAILABLE"
  | "CONNECTION_TIMEOUT"
  | "UNIT_NOT_REGISTERED"
  | "SUCCESS"
  | "";

export enum HostCategory {
  VENDING = "VENDING",
  AMUSE_NON_GAMB = "AMUSE_NON_GAMB",
  AMUSE_GAMB = "AMUSE_GAMB",
}
export interface UnitDetailsInterface {
  errorCode: string;
  status: "READY" | "ERROR" | "";
  flowStatus: FlowStatus | "";
  paymentProcessor: PaymentProcessor | "";
  photoUrl: string;
  hostCategory: HostCategory | "";
  creditType: string;
  defaultTransactionAmount: number;
  hostType: HostTypeInterface;
  currency: CurrencyDetailsInterface;
  vendingRefs?: string[];
  vendingPrices?: string[];
  vendingSpecialButtons?: string[];
  unitPowerSource?: "BATTERY" | "MAINS" | "" | null;
}

export interface HostTypeInterface {
  hostCategory: HostCategory | null;
  hostTypeId: number;
  code: string;
  maxTransactionAmount: number;
  name: string;
}

export interface CurrencyDetailsInterface {
  id: number;
  code: string;
  number: number;
  minorUnit: number;
  name: string;
  symbol: string;
}

export interface UnitIdInterface {
  unitId: string;
}

export interface TransactionPaymentStatusDetails {
  transactionStatus: TransactionPaymentStatus;
  transactionStatusTitle: string;
  transactionStatusBody: string;
  paymentJobReference: string;
  transactionAmount: number;
}

export type TransactionPaymentStatus =
  | "success"
  | "warning"
  | "timeout"
  | "error"
  | "denied"
  | "";

export interface IKeyboardStatePosition {
  keyboard: boolean;
}
