// useSocketConnection.ts
import { useState, useEffect, useCallback } from "react";
import SockJS from "sockjs-client";
import { Client } from "@stomp/stompjs";

import {
  HostCategory,
  PaymentDetails,
  TransactionPaymentStatus,
  UnitDetailsInterface,
} from "../../@types";
import {
  DENIED_ERROR_MESSAGE_BODY,
  DENIED_ERROR_MESSAGE_TITLE,
  PENDING_PAYMENT_BODY,
  PENDING_PAYMENT_TITLE,
  SUCCESS_MESSAGE_BODY,
  SUCCESS_MESSAGE_TITLE,
  SYSTEM_ERROR_TITLE,
  TIMEOUT_PAYMENT_BODY,
  TIMEOUT_PAYMENT_TITLE,
  UNIT_DETAILS_INITIAL_STATE,
  VENDING_SUCCESS_MESSAGE_BODY,
} from "../../constants";
import { hasValidFlowStatus } from "../../utils/unitUtils";

export const useSocketConnection = () => {
  const [transactionStatus, setTransactionStatus] = useState<
    TransactionPaymentStatus | ""
  >("");
  const [transactionStatusTitle, setTransactionStatusTitle] = useState("");
  const [transactionStatusBody, setTransactionStatusBody] = useState("");
  const [transactionAmount, setTransactionAmount] = useState(0);
  const [paymentJobReference, setPaymentJobReference] = useState("");
  const [generalLoading, setGeneralLoading] = useState(true);

  const [unitId, setUnitId] = useState("");
  const [unitDetailsType, setUnitDetailsType] = useState("");
  const [paymentNonce, setPaymentNonce] = useState<string | null>(null);
  const [paymentLink, setPaymentLink] = useState("");
  const [userId, setUserId] = useState<string | undefined>();
  const [unitDetails, setUnitDetails] = useState<UnitDetailsInterface>(
    UNIT_DETAILS_INITIAL_STATE
  );

  const [stompClientState, setStompClientState] = useState<Client | null>(null);
  const [messageToSend, setMessageToSend] = useState<string | undefined>();
  const [socketReceivedMessage, setSocketReceivedMessage] = useState();
  const [winnings, setWinnings] = useState(0.0);
  const params = new URLSearchParams(window.location.search.substring(1));
  const userIdFromUrl = params.get("userId");

  //Demo stuff
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);

  useEffect(() => {
    const urlPath = window.location.pathname;
    let id = urlPath.split("/")[1];
    if (userIdFromUrl) {
      setUserId(userIdFromUrl);
    }

    // Create new STOMP client
    const stompClient = new Client({
      webSocketFactory: () =>
        new SockJS(`${process.env.REACT_APP_SOCK_BASE_URL}`),
      connectHeaders: userIdFromUrl ? { userId: `${userIdFromUrl}` } : {},
      debug: function (str) {
        console.debug(str);
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });

    // Set up event handlers
    stompClient.onConnect = (frame) => {
      // Subscribe to topics
      stompClient.subscribe("/user/payApp/paymentResponse", (message) => {
        setSocketReceivedMessage(JSON.parse(message.body));
      });

      stompClient.subscribe("/user/payApp/userDetailsResponse", (message) => {
        onReceivedSocketUnitDetails(JSON.parse(message.body));
      });

      stompClient.subscribe("/user/payApp/winnings", (message) => {
        onReceivedWinningDetails(JSON.parse(message.body));
      });

      // Send initial unit details request
      if (!userIdFromUrl) {
        stompClient.publish({
          destination: "/server/unitDetails",
          body: JSON.stringify(id),
        });
      }
    };

    stompClient.onStompError = (frame) => {
      console.error("Broker reported error: " + frame.headers["message"]);
      console.error("Additional details: " + frame.body);
    };

    // Activate the client
    stompClient.activate();

    setStompClientState(stompClient);
    setMessageToSend(id);
    setUnitId(id);
    setGeneralLoading(true);

    // Clean up
    return () => {
      if (stompClient.active) {
        stompClient.deactivate();
      }
    };
  }, []);

  useEffect(() => {
    if (stompClientState?.active && messageToSend) {
      if (
        unitDetails.hostCategory === HostCategory.VENDING &&
        messageToSend === paymentNonce &&
        hasValidFlowStatus(unitDetails.flowStatus)
      ) {
        console.log(
          "UnitContainer:: Sending contactless payment for Vending Machine to /server/performPayment"
        );
        stompClientState.publish({
          destination: "/server/performPayment",
          body: JSON.stringify(messageToSend),
        });
        setGeneralLoading(true);
      }
    }
  }, [messageToSend, stompClientState, unitDetails, paymentNonce]);

  useEffect(() => {
    if (stompClientState?.active && messageToSend) {
      if (
        unitDetails?.hostCategory &&
        (unitDetails.hostCategory === HostCategory.AMUSE_NON_GAMB ||
          unitDetails.hostCategory === HostCategory.AMUSE_GAMB) &&
        paymentNonce !== null &&
        messageToSend === paymentNonce &&
        hasValidFlowStatus(unitDetails.flowStatus)
      ) {
        console.log(
          "UnitContainer:: Sending contactless payment for Pool Machine to /server/performPayment"
        );
        stompClientState.publish({
          destination: "/server/performPayment",
          body: JSON.stringify(messageToSend),
        });
        console.log(
          "UnitContainer:: Payment Values sent: ",
          JSON.stringify(messageToSend)
        );
        setGeneralLoading(true);
      }
    }
  }, [unitDetails, paymentNonce, messageToSend, stompClientState]);

  useEffect(() => {
    setUnitDetailsType(unitDetails.hostCategory);
  }, [unitDetails, paymentNonce]);

  const onReceivedSocketUnitDetails = (msg: any) => {
    console.log(
      "UnitContainer:: (onReceivedSocketUnitDetails) Received message",
      msg
    );
    setUnitDetails(msg);
    setUnitDetailsType(msg.hostCategory);
    msg.flowStatus !== "PAYMENT_PROCESS_INITIALIZED" &&
      setGeneralLoading(false);
    if (msg.paymentLink && msg.paymentLink !== "") {
      setPaymentLink(msg.paymentLink);
    }
  };

  const onReceivedWinningDetails = (msg: any) => {
    console.log(
      "UnitContainer:: (onReceivedWinningDetails) Received winnings",
      msg
    );
    setWinnings(parseFloat(parseFloat(msg.amount).toFixed(2)));
  };

  const onSocketPaymentStatusMessage = useCallback(
    (msg: any) => {
      console.log(
        "UnitContainer:: (onSocketPaymentStatusMessage) Received payment status message",
        msg
      );
      setGeneralLoading(false);
      if (userIdFromUrl) {
        setUnitDetails({
          ...unitDetails,
          hostCategory: msg.hostCategory,
          hostType: { ...msg.hostType },
          photoUrl: msg.photoUrl,
        });
      }
      if (msg.paymentLink && msg.paymentLink !== "") {
        setPaymentLink(msg.paymentLink);
      } else if (msg.paymentStatus === "COMPLETED" || msg === "COMPLETED") {
        setTransactionStatus("success");
        setTransactionStatusTitle(SUCCESS_MESSAGE_TITLE);
        setTransactionAmount(msg.transactionAmount);
        if (unitDetailsType === "VENDING") {
          setTransactionStatusBody(VENDING_SUCCESS_MESSAGE_BODY);
        } else {
          setTransactionStatusBody(SUCCESS_MESSAGE_BODY);
        }
        return;
      } else if (msg.paymentStatus === "PENDING" || msg === "PENDING") {
        setTransactionStatus("warning");
        setPaymentJobReference(msg.paymentJobReference);
        setTransactionStatusTitle(PENDING_PAYMENT_TITLE);
        setTransactionStatusBody(PENDING_PAYMENT_BODY);
        return;
      } else if (msg.paymentStatus === "TIMEOUT" || msg === "TIMEOUT") {
        setTransactionStatus("timeout");
        setTransactionStatusTitle(TIMEOUT_PAYMENT_TITLE);
        setTransactionStatusBody(TIMEOUT_PAYMENT_BODY);
        return;
      } else if (msg.paymentStatus === "DENIED" || msg === "DENIED") {
        setTransactionStatus("denied");
        setTransactionStatusTitle(DENIED_ERROR_MESSAGE_TITLE);
        setTransactionStatusBody(DENIED_ERROR_MESSAGE_BODY);
        return;
      } else if (msg.paymentStatus === "ERROR" || msg === "ERROR") {
        setTransactionStatus("error");
        setTransactionStatusTitle(SYSTEM_ERROR_TITLE);
        return;
      }
    },
    [unitDetails, userIdFromUrl, unitDetailsType]
  );

  const handleNonce = (nonce: string) => {
    setPaymentNonce(nonce);
    setMessageToSend(nonce);
  };

  const handlePaymentSuccess = (
    transactionId: string,
    nonce: PaymentDetails
  ) => {
    setGeneralLoading(true);
    setPaymentSuccess(true);
    setTransactionAmount(nonce.amount);
  };

  const handlePaymentFailed = () => {
    setGeneralLoading(true);
    setPaymentFailed(true);
  };

  const handleFlowCompleted = () => {
    setTransactionStatus("");
    setTransactionAmount(0);
    setTransactionStatusTitle("");
    setTransactionStatusBody("");
  };

  const handleWinningsCollection = () => {
    setWinnings(0.0);
  };

  useEffect(() => {
    if (paymentFailed && stompClientState?.active) {
      const paymentRequest = {
        paymentJobReference: paymentJobReference,
        paymentStatus: "Failed",
      };
      console.log("UnitContainer:: Sending payment failed", paymentRequest);
      stompClientState.publish({
        destination: "/server/webhook",
        body: JSON.stringify(paymentRequest),
      });
    }
  }, [paymentFailed, stompClientState, paymentJobReference]);

  useEffect(() => {
    if (paymentSuccess && stompClientState?.active) {
      const paymentRequest = {
        paymentJobReference: paymentJobReference,
        paymentStatus: "Paid",
      };
      console.log("UnitContainer:: Sending payment success", paymentRequest);
      stompClientState.publish({
        destination: "/server/webhook",
        body: JSON.stringify(paymentRequest),
      });
    }
  }, [paymentSuccess, stompClientState, paymentJobReference]);

  useEffect(() => {
    if (socketReceivedMessage) {
      onSocketPaymentStatusMessage(socketReceivedMessage);
    }
  }, [onSocketPaymentStatusMessage, socketReceivedMessage]);

  return {
    unitId,
    userId,
    unitDetails,
    unitDetailsType,
    socketReceivedMessage,
    winnings,
    paymentLink,
    transactionStatus,
    transactionStatusTitle,
    transactionStatusBody,
    transactionAmount,
    generalLoading,
    paymentNonce,
    paymentJobReference,
    stompClientState,
    messageToSend,
    handleNonce,
    handlePaymentSuccess,
    handlePaymentFailed,
    handleFlowCompleted,
    handleWinningsCollection,
  };
};
