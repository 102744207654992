import { useEffect, useState } from "react";
import {
  ERROR_MESSAGE_BODY,
  ERROR_MESSAGE_TITLE,
  TIMEOUT_PAYMENT_BODY,
  TIMEOUT_PAYMENT_BODY_BATTERY,
  TIMEOUT_PAYMENT_BODY_MAINS,
  TIMEOUT_PAYMENT_TITLE,
  VENDING_ERROR_MESSAGE_BODY,
} from "../../constants";
import { HostCategory, UnitDetailsInterface } from "../../@types";

export const useMessagesRetriever = (unitDetails: UnitDetailsInterface) => {
  const [isDisplayedPaymentButton, setIsDisplayedPaymentButton] =
    useState(false);
  const [isDisplayedLoading, setIsDisplayedLoading] = useState(true);

  const [messageType, setMessageType] = useState("");
  const [messageTitle, setMessageTitle] = useState("");
  const [messageBody, setMessageBody] = useState("");

  const setMessageDetails = (type: string, title: string, body: string) => {
    setMessageType(type);
    setMessageTitle(title);
    setMessageBody(body);
  };

  const specificMessageBody =
    unitDetails.hostCategory === HostCategory.VENDING
      ? VENDING_ERROR_MESSAGE_BODY
      : ERROR_MESSAGE_BODY;

  useEffect(() => {
    if (
      unitDetails.status === "READY" &&
      unitDetails.flowStatus === "SUCCESS"
    ) {
      setIsDisplayedLoading(false);
      setIsDisplayedPaymentButton(true);
      console.log("unit details ready", unitDetails);
      return;
    }

    if (
      unitDetails.status === "ERROR" ||
      unitDetails.flowStatus === "CONTACTLESS_UNAVAILABLE"
    ) {
      console.log("unit details error", unitDetails);
      setIsDisplayedLoading(false);
      setMessageDetails(
        "unavailable",
        ERROR_MESSAGE_TITLE,
        specificMessageBody
      );
      return;
    }

    if (unitDetails.flowStatus === "CONNECTION_TIMEOUT") {
      console.log("unit details timeout", unitDetails);
      setIsDisplayedLoading(false);
      unitDetails.unitPowerSource === "BATTERY" &&
        setMessageDetails(
          "timeout",
          TIMEOUT_PAYMENT_TITLE,
          TIMEOUT_PAYMENT_BODY_BATTERY
        );
      unitDetails.unitPowerSource === "MAINS" &&
        setMessageDetails(
          "timeout",
          TIMEOUT_PAYMENT_TITLE,
          TIMEOUT_PAYMENT_BODY_MAINS
        );
      !unitDetails.unitPowerSource &&
        setMessageDetails(
          "timeout",
          TIMEOUT_PAYMENT_TITLE,
          TIMEOUT_PAYMENT_BODY
        );
      return;
    }
  }, [unitDetails, specificMessageBody]);

  return [
    isDisplayedPaymentButton,
    isDisplayedLoading,
    messageType,
    messageTitle,
    messageBody,
  ];
};
