import { FC } from "react";
import { DashboardStyles } from "./styles";
import PersonIcon from "@mui/icons-material/Person";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import RoomIcon from "@mui/icons-material/Room";
import QrCodeIcon from "@mui/icons-material/QrCode";
import {
  BALANCE,
  LOYALTY,
  POUND_KEY,
  PROFILE,
  SCAN,
  WHERE_TO_PLAY,
  WINNINGS,
} from "../../constants";
import { CurrencyPound } from "@mui/icons-material";

interface Props {
  balance: number;
  winnings?: number | null;
  onClick: Function;
}

export enum DashboardOption {
  LOYALTY = "loyalty",
  PROFILE = "profile",
  WINNINGS = "winnings",
  WHERE_TO_BUY = "where",
}

export const Dashboard: FC<Props> = ({ balance, onClick, winnings }) => {
  return (
    <DashboardStyles>
      <div className="options-grid">
        <div
          className="loyalty"
          onClick={() => onClick(DashboardOption.LOYALTY)}
        >
          <div className="text-icon">
            <LoyaltyIcon className="icon"></LoyaltyIcon>
            <label className="text">{LOYALTY}</label>
          </div>
        </div>
        <div
          className="profile"
          onClick={() => onClick(DashboardOption.PROFILE)}
        >
          <div className="text-icon">
            <PersonIcon className="icon"></PersonIcon>
            <label className="text">{PROFILE}</label>
          </div>
        </div>
        <div
          className="winnings"
          onClick={() => onClick(DashboardOption.WINNINGS)}
        >
          <div className="text-icon">
            <CurrencyPound className="icon"></CurrencyPound>
            <label className="text">{WINNINGS}</label>
            <label className="amount">
              {winnings != null && winnings ? POUND_KEY : ""}
              {winnings != null && winnings > 0 ? winnings : ""}
            </label>
          </div>
        </div>
        <div
          className="where"
          onClick={() => onClick(DashboardOption.WHERE_TO_BUY)}
        >
          <div className="text-icon">
            <RoomIcon className="icon"></RoomIcon>
            <label className="text">{WHERE_TO_PLAY}</label>
          </div>
        </div>
      </div>
      <div className="balance-circle">
        <div className="amount">
          {POUND_KEY}
          {(Math.round(balance * 100) / 100).toFixed(2)}
        </div>
        <div className="balance">{BALANCE}</div>
      </div>
      <div className="scan-circle">
        <div className="text-icon">
          <QrCodeIcon className="icon"></QrCodeIcon>
          <label className="text">{SCAN}</label>
        </div>
      </div>
    </DashboardStyles>
  );
};
