import { FC } from "react";

import { UnitDetailsInterface, PaymentDetails } from "../../../@types";
import CashFlowPayment from "../CashFlowPayment";
import PaymentContainer from "../SqPayment/PaymentContainer";
import VivaPayment from "../VivaPayment";

interface PaymentProcessorWrapperProps {
  unitDetails: UnitDetailsInterface;
  isDisplayedPaymentButton: boolean;
  isDisplayedLoading: boolean;
  paymentValue: string;
  paymentLink: string;
  demo: boolean;
  onClickPayBtnHandler: () => void;
  handleNonce: (nonce: PaymentDetails) => void;
  POOL_PAYMENT_VALUE: number;
  CATEGORY_UNIT: string;
}

const PaymentProcessorWrapper: FC<PaymentProcessorWrapperProps> = ({
  unitDetails,
  isDisplayedPaymentButton,
  isDisplayedLoading,
  paymentValue,
  paymentLink,
  demo,
  onClickPayBtnHandler,
  handleNonce,
  POOL_PAYMENT_VALUE,
  CATEGORY_UNIT,
}) => {
  return (
    <div className="wrapper">
      {unitDetails.paymentProcessor === "SQUARE" && (
        <PaymentContainer
          isDisplayedPaymentButton={isDisplayedPaymentButton}
          paymentValuePerUnit={POOL_PAYMENT_VALUE}
          categoryUnit={CATEGORY_UNIT}
          isVending={false}
          isVendingLoading={isDisplayedLoading}
          handleNonce={handleNonce}
        />
      )}
      {unitDetails.paymentProcessor === "CASHFLOWS" && (
        <CashFlowPayment
          paymentLink={paymentLink}
          paymentValue={paymentValue}
          unitDetails={unitDetails}
          demo={demo}
          onClickPayBtnHandler={onClickPayBtnHandler}
        />
      )}
      {unitDetails.paymentProcessor === "VIVA" && (
        <VivaPayment
          paymentValue={paymentValue}
          unitDetails={unitDetails}
          demo={demo}
          onClickPayBtnHandler={onClickPayBtnHandler}
          paymentLink={paymentLink}
        />
      )}
    </div>
  );
};

export default PaymentProcessorWrapper;
