import styled from "styled-components";
import media from "../../../assets/styling/payme-media-queries";
import PaymeTheme from "../../../assets/styling/payme-theme";

const StyledVivaPayment = styled.div`
  text-align: center;
  background-color: ${PaymeTheme.footerBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .loading-wrapper {
    margin-top: ${PaymeTheme.paddingRemLg};
  }

  .viva-button {
    background-color: transparent;
    border-radius: 10px;
    color: ${PaymeTheme.colorWhite};
    font-size: ${PaymeTheme.fontSizeRemH5};
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 3.5rem;
    width: 100%;
    max-width: 12.125rem;
    margin: 0.625rem auto;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    border: 2px solid ${PaymeTheme.colorWhite};

    &:disabled {
      background-color: ${PaymeTheme.lightGrey};
      color: ${PaymeTheme.colorBlack};
      border-color: ${PaymeTheme.lightGrey};
    }

    &:focus {
      outline: none;
    }
  }

  ${media.desktopMedium} {
    margin: 0;
  }
`;

const StyledVivaResponse = styled.div`
  .unit-category-name {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    margin: auto;
    color: ${({ theme }) => theme.colorWhite};
    font-size: ${({ theme }) => theme.fontSizeRemH3};
    font-weight: ${({ theme }) => theme.fontBold};
    padding: ${({ theme }) => theme.paddingRemXs};
  }

  .unit-payment-value {
    position: absolute;
    top: 130px;
    left: 0;
    right: 0;
    margin: auto;
    color: ${({ theme }) => theme.colorWhite};
    font-size: ${({ theme }) => theme.fontSizeRemH1};
    padding: ${({ theme }) => theme.paddingRemXs};
  }

  ${media.desktopMedium} {
    margin: 0;
  }
`;
export { StyledVivaPayment, StyledVivaResponse };
