import { FC } from "react";
import { PaymentDetails, TransactionPaymentStatus } from "../../@types";
import { NO_MACHINE_ERROR_BODY, UNIT_NOT_REGISTERED } from "../../constants";
import { Dashboard } from "../Dashboard";
import PoolTableCommunication from "../PoolTableCommunication";
import { StyledNoUnit } from "../Containers/UnitsContainer/styles";
import PlaceholderIconSvg from "../common/PlaceholderIcon";
import MessageNotification from "../common/Message";
import LoadingComponent from "../common/Loading";
import { FruitMachineStyles } from "./styles";
import { Payment } from "../paymentProcessor/Payment";
import { useFruitMachineFlow } from "../../hooks/units/useFruitMachineFlow";

interface Props {
  unitId: string;
  userId: string;
  unitDetails: any;
  unitDetailsType: string;
  generalLoading: boolean;
  paymentLink: string;
  paymentNonce?: string | null;
  handleNonce: Function;
  onPaymentSuccess: Function;
  onPaymentFailed: Function;
  onFlowComplete: Function;
  onCollectWinnings: Function;
  transactionStatus?: TransactionPaymentStatus | null;
  transactionAmount?: number;
  paymentJobReference?: string | null;
  winnings?: number | null;
}

export const FruitMachineFlow: FC<Props> = ({
  unitDetailsType,
  unitDetails,
  unitId,
  userId,
  generalLoading,
  paymentNonce,
  paymentLink,
  handleNonce,
  onPaymentSuccess,
  onPaymentFailed,
  onFlowComplete,
  onCollectWinnings,
  paymentJobReference,
  transactionStatus,
  winnings,
  transactionAmount,
}) => {
  const {
    balance,
    shouldShowDemoDashboard,
    shouldShowPoolTableConnection,
    shouldShowPaymentScreen,
    handleDashboardClick,
    handlePaymentDetails,
  } = useFruitMachineFlow({
    unitDetailsType,
    unitId,
    transactionStatus,
    paymentJobReference,
    winnings,
    transactionAmount,
    handleNonce,
    onCollectWinnings,
    onFlowComplete,
  });

  return (
    <FruitMachineStyles>
      <div className="machines-wrapper new-flow">
        {shouldShowDemoDashboard() && (
          <Dashboard
            balance={balance}
            winnings={winnings}
            onClick={handleDashboardClick}
          />
        )}
        {shouldShowPoolTableConnection() && (
          <PoolTableCommunication
            handleNonce={(nonce: PaymentDetails) => handlePaymentDetails(nonce)}
            unitDetails={unitDetails}
            showGeneralLoading={generalLoading}
            userId={userId}
            demo={true}
            paymentLink={paymentLink}
          />
        )}
        {shouldShowPaymentScreen() && (
          <Payment
            nonce={paymentNonce}
            paymentJobReference={paymentJobReference}
            generalLoading={generalLoading}
            onPaymentSuccess={onPaymentSuccess}
            onPaymentFailed={onPaymentFailed}
          />
        )}
        {(!unitDetailsType || unitDetailsType === "") &&
          unitDetails.flowStatus === "UNIT_NOT_REGISTERED" &&
          !generalLoading && (
            <StyledNoUnit>
              <div className="placeholder-wrapper">
                <PlaceholderIconSvg />
              </div>
              <MessageNotification
                messageType="error"
                messageTitle={UNIT_NOT_REGISTERED}
                messageBody={NO_MACHINE_ERROR_BODY}
              />
            </StyledNoUnit>
          )}
        {(!unitDetailsType || unitDetailsType === "") && generalLoading && (
          <div
            style={{ display: "flex", justifyContent: "center", flexGrow: 1 }}
          >
            <LoadingComponent displayLoadingMessage={true} />
          </div>
        )}
      </div>
    </FruitMachineStyles>
  );
};
