import styled from "styled-components";

const FruitMachineStyles = styled.div((props) => {
  return `
    .new-flow {
      width: 100%;
      min-width: 280px;
      display: flex;
      flex-direction: column;
      max-height:calc(100vh - 5rem)!important;
      overflow-y: hidden;
      overflow-x: hidden;
      box-sizing: border-box;
    }
      
`;
});

export { FruitMachineStyles };
