import React, { FC } from "react";
import { StyledLoading } from "./styles";
import Loading from "../../../assets/images/Loading.svg";

interface Props {
  displayLoadingMessage: boolean;
}

const LoadingComponent: FC<Props> = ({ displayLoadingMessage = false }) => {
  return (
    <StyledLoading theme={{ displayLoadingMessage }}>
      {!!displayLoadingMessage && (
        <div className="loading-information">
          <p>Communicating, please wait.</p>
        </div>
      )}
      <div className="loading-svg-wrapper">
        <img src={Loading} alt="loading..." />
      </div>
    </StyledLoading>
  );
};

export default LoadingComponent;
