import { FC, useState } from "react";
import { StyledUnitDetails } from "./styles";
import {
  TransactionPaymentStatusDetails,
  UnitDetailsInterface,
} from "../../@types";
import { PriceChanger } from "./components/PriceChanger";
import { SingleValuePayment } from "./components/SingleValuePayment";
import { VendingSelection } from "./components/VendingSelection";
import {
  hasValidFlowStatus,
  hasTransactionStatus,
} from "../../utils/unitUtils";

interface Props {
  categoryUnit: string;
  paymentValuePerUnit: number;
  selectionStatus?: string;
  isVending?: boolean;
  userSelection?: string;
  userSpecialButton?: string;
  photoUrl: string;
  userId?: string;
  transactionPaymentStatusDetails: TransactionPaymentStatusDetails;
  unitDetails: UnitDetailsInterface;
  isPayButtonPressed: boolean;
  onChangePaymentValueHandler: (value: string) => void;
  isLoading?: boolean;
  paymentLink?: string;
}

const UnitDetails: FC<Props> = ({
  unitDetails,
  userId = false,
  paymentValuePerUnit,
  selectionStatus,
  isVending,
  userSelection,
  onChangePaymentValueHandler,
  userSpecialButton = "",
  photoUrl,
  transactionPaymentStatusDetails,
  isPayButtonPressed,
  isLoading,
  paymentLink,
}) => {
  const [inputError, setInputError] = useState("");
  const defaultTransactionAmount = unitDetails.defaultTransactionAmount || 1.0;
  const isHostSingleValue = unitDetails.creditType === "HOST_SING_VAL";
  const [priceValue, setPriceValue] = useState(defaultTransactionAmount);

  const addDecimals = (value: string) => {
    const priceValueTwoDecimals = `${value}`.split(".");
    if (priceValueTwoDecimals[1]) {
      if (priceValueTwoDecimals[1].length === 1) {
        return priceValueTwoDecimals[0] + "." + priceValueTwoDecimals[1] + "0";
      } else {
        return value;
      }
    } else {
      return priceValueTwoDecimals[0] + ".00";
    }
  };

  const changeValue = (value: number) => {
    const currentValue = priceValue + value;

    if (
      currentValue >= defaultTransactionAmount &&
      currentValue <= unitDetails.hostType.maxTransactionAmount
    ) {
      onChangePaymentValueHandler(currentValue.toFixed(2).toString());
      setPriceValue(parseFloat(currentValue.toFixed(2)));
      setInputError("");
    } else if (currentValue > unitDetails.hostType.maxTransactionAmount) {
      setInputError(
        `Credit value has to be smaller than ${unitDetails.hostType.maxTransactionAmount}`
      );
    } else {
      setInputError(
        `Credit value has to be higher than ${defaultTransactionAmount.toFixed(
          2
        )}`
      );
    }
  };
  const isValidFlowStatus = hasValidFlowStatus(unitDetails.flowStatus);

  const enableChangeValueButtons =
    unitDetails.status === "READY" &&
    !userId &&
    !isPayButtonPressed &&
    !isVending &&
    !hasTransactionStatus(transactionPaymentStatusDetails.transactionStatus) &&
    isValidFlowStatus;

  const shouldShowPriceChanger =
    isValidFlowStatus &&
    !isVending &&
    !userId &&
    !isHostSingleValue &&
    !isLoading &&
    transactionPaymentStatusDetails.transactionStatus === "" &&
    paymentLink === "";

  const shouldShowSingleValue =
    isValidFlowStatus &&
    !isVending &&
    !userId &&
    isHostSingleValue &&
    unitDetails?.currency?.symbol &&
    !!defaultTransactionAmount;

  const shouldShowVendingSelection = isValidFlowStatus && isVending && !userId;

  return (
    <StyledUnitDetails>
      <div className="image-unit-wrapper">
        <img src={photoUrl || undefined} alt="unit" />
      </div>

      {shouldShowPriceChanger && (
        <PriceChanger
          priceValue={priceValue}
          inputError={inputError}
          enableChangeValueButtons={enableChangeValueButtons}
          changeValue={changeValue}
          addDecimals={addDecimals}
        />
      )}

      <div className="unit-content-wrapper">
        {unitDetails?.hostType && (
          <div className="unit-category-name">{unitDetails.hostType.name}</div>
        )}

        {shouldShowSingleValue && (
          <SingleValuePayment
            currencySymbol={unitDetails?.currency?.symbol}
            defaultTransactionAmount={defaultTransactionAmount}
            addDecimals={addDecimals}
          />
        )}

        {shouldShowVendingSelection && (
          <VendingSelection
            selectionStatus={selectionStatus}
            paymentValuePerUnit={paymentValuePerUnit}
            userSelection={userSelection}
            userSpecialButton={userSpecialButton}
            currencySymbol={unitDetails?.currency?.symbol}
          />
        )}
      </div>
    </StyledUnitDetails>
  );
};

export default UnitDetails;
