// @ts-nocheck
import React, { FC, useEffect, useState } from "react";
import PaymentForm from "./PaymentForm";
import { PaymentDetails } from "../../@types";
import LoadingComponent from "../../common/Loading";

declare global {
  interface Window {
    SqPaymentForm: () => any;
  }
}

interface Props {
  isDisplayedPaymentButton: boolean;
  paymentValuePerUnit: number;
  categoryUnit: string;
  isVending: boolean;
  userSpecialButton?: string;
  userSelection?: string;
  handleNonce: (nonce: PaymentDetails) => void;
  handleIsVendingLoading?: (isLoading: boolean) => void;
  isVendingLoading: boolean;
}

const PaymentContainer: FC<Props> = ({
  isDisplayedPaymentButton,
  paymentValuePerUnit,
  categoryUnit,
  isVending,
  userSelection,
  userSpecialButton,
  handleNonce,
  handleIsVendingLoading,
  isVendingLoading,
}) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    let sqPaymentScript = document.createElement("script");
    sqPaymentScript.src = `${process.env.REACT_APP_SQUARE_SCRIPT}`;
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {
      setIsScriptLoaded(true);
      if (isVending) {
        handleIsVendingLoading(false);
      }
    };

    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  }, [
    isScriptLoaded,
    isDisplayedPaymentButton,
    isVendingLoading,
    handleIsVendingLoading,
    isVending,
  ]);

  if (isVending && isVendingLoading) {
    return <LoadingComponent displayLoadingMessage={false} />;
  }

  if (!isScriptLoaded) {
    return null;
  }

  return (
    <div>
      <PaymentForm
        paymentForm={window.SqPaymentForm}
        paymentValuePerUnit={paymentValuePerUnit}
        categoryUnit={categoryUnit}
        isVending={isVending}
        handleNonce={handleNonce}
        userSpecialButton={userSpecialButton}
        userSelection={userSelection}
      />
    </div>
  );
};

export default PaymentContainer;
