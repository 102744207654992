import { createContext, FC, useMemo } from "react";

import { TransactionPaymentStatusDetails } from "../../../@types";
import { FruitMachineFlow } from "../../FruitMachineFlow";
import { useSocketConnection } from "../../../hooks/generalHooks/useSocketConnection";

export const UnitIdContext = createContext("");
export const TransactionStatusDetails =
  createContext<TransactionPaymentStatusDetails>({
    paymentJobReference: "",
    transactionStatus: "",
    transactionStatusTitle: "",
    transactionStatusBody: "",
    transactionAmount: 0.0,
  });

const UnitsContainer: FC = () => {
  const {
    transactionStatus,
    transactionStatusTitle,
    transactionStatusBody,
    paymentJobReference,
    transactionAmount,
    userId,
    unitId,
    unitDetails,
    winnings,
    unitDetailsType,
    paymentLink,
    generalLoading,
    paymentNonce,
    handlePaymentFailed,
    handlePaymentSuccess,
    handleFlowCompleted,
    handleWinningsCollection,
    handleNonce,
  } = useSocketConnection();

  /**
   * The options menu is currently shown only for fruit machines that are registered for the demo
   */
  // Correct implementation
  const contextValue = useMemo(
    () => ({
      transactionStatus,
      transactionStatusTitle,
      transactionStatusBody,
      paymentJobReference,
      transactionAmount,
    }),
    [
      transactionStatus,
      transactionStatusTitle,
      transactionStatusBody,
      paymentJobReference,
      transactionAmount,
    ]
  );

  return (
    <UnitIdContext.Provider value={unitId}>
      <TransactionStatusDetails.Provider value={contextValue}>
        {
          <FruitMachineFlow
            userId={userId ?? ""}
            unitId={unitId}
            unitDetails={unitDetails}
            winnings={winnings}
            unitDetailsType={unitDetailsType}
            paymentLink={paymentLink}
            onPaymentFailed={handlePaymentFailed}
            onPaymentSuccess={handlePaymentSuccess}
            onFlowComplete={handleFlowCompleted}
            onCollectWinnings={handleWinningsCollection}
            generalLoading={generalLoading}
            paymentNonce={paymentNonce}
            paymentJobReference={paymentJobReference}
            transactionStatus={transactionStatus}
            transactionAmount={transactionAmount}
            handleNonce={handleNonce}
          />
        }
      </TransactionStatusDetails.Provider>
    </UnitIdContext.Provider>
  );
};

export default UnitsContainer;
