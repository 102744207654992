import React from "react";
import VivaResponse from "./VivaResponse";

const VivaFail: React.FC = () => {
  return (
    <VivaResponse
      messageType="error"
      messageBody="Your payment was unsuccessful."
      messageTitle="Transaction Unsuccessful"
    />
  );
};

export default VivaFail;
