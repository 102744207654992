import { FC } from "react";
import { PaymentStyles } from "./styles";
import {
  LIVE_SIMULATION,
  FAILED_PAYMENT,
  SUCCESS_PAYMENT,
} from "../../../constants";

import LoadingComponent from "../../common/Loading";
import { LogoSvgComponent } from "../../common/Footer/FooterSvgLogo";
interface Props {
  nonce?: any;
  onPaymentSuccess: Function;
  onPaymentFailed: Function;
  paymentJobReference?: string | null;
  generalLoading?: boolean;
}

export const Payment: FC<Props> = ({
  nonce,
  onPaymentSuccess,
  onPaymentFailed,
  paymentJobReference,
  generalLoading,
}) => {
  return (
    <PaymentStyles>
      <div className="payment">
        <LogoSvgComponent className="title" />
        <p className="amount">£{nonce?.amount ?? 0}</p>
        <button className="button live">{LIVE_SIMULATION}</button>
        {!generalLoading && (
          <button
            className="button failed"
            onClick={() => onPaymentFailed(paymentJobReference, nonce)}
          >
            {FAILED_PAYMENT}
          </button>
        )}
        {!generalLoading && (
          <button
            className="button success"
            onClick={() => onPaymentSuccess(paymentJobReference, nonce)}
          >
            {SUCCESS_PAYMENT}
          </button>
        )}
        {generalLoading && <LoadingComponent displayLoadingMessage={true} />}
      </div>
    </PaymentStyles>
  );
};
