import { FlowStatus, TransactionPaymentStatus } from "../@types";
import {
  INVALID_FLOW_STATUSES,
  TRANSACTION_PAYMENT_STATUSES,
} from "../constants";

export const hasValidFlowStatus = (flowStatus: FlowStatus): boolean => {
  return INVALID_FLOW_STATUSES.indexOf(flowStatus) === -1;
};

export const hasTransactionStatus = (
  transactionStatus: TransactionPaymentStatus
): boolean => {
  return TRANSACTION_PAYMENT_STATUSES.indexOf(transactionStatus) !== -1;
};

export const extractUnitIdFromPath = (): string => {
  const urlPath = window.location.pathname;
  return urlPath.split("/")[1] || "";
};

export const extractUserIdFromUrl = (): string | null => {
  const params = new URLSearchParams(window.location.search.substring(1));
  return params.get("userId");
};
