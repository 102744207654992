import { FC, useContext, useEffect, useState } from "react";
import { StyledPaymentForm } from "./styles";
import ApplePayIcon from "../../../assets/images/ApplePayIcon.svg";
import { isSafari } from "react-device-detect";
import {
  PaymentDetails,
  TransactionPaymentStatusDetails,
} from "../../../@types";
import LoadingComponent from "../../common/Loading";
import MessageNotification from "../../common/Message";
import {
  UnitIdContext,
  TransactionStatusDetails,
} from "../../Containers/UnitsContainer";

interface Props {
  paymentForm: any;
  paymentValuePerUnit: number;
  categoryUnit: string;
  isVending: boolean;
  userSpecialButton: string;
  userSelection: string;
  handleNonce: (nonce: PaymentDetails) => void;
}

const PaymentForm: FC<Props> = ({
  paymentForm,
  paymentValuePerUnit,
  categoryUnit,
  userSelection,
  userSpecialButton,
  isVending,
  handleNonce,
}) => {
  const [googlePay, setGooglePay] = useState(false);
  const [applePay, setApplePay] = useState(false);
  const [localPaymentForm, setLocalPaymentForm] = useState(null);
  const [showLoading, setShowLoading] = useState(false);

  const unitId = useContext(UnitIdContext);
  const transactionPaymentStatusDetails =
    useContext<TransactionPaymentStatusDetails>(TransactionStatusDetails);

  useEffect(() => {
    const config = {
      applicationId: `${process.env.REACT_APP_SQUARE_APP_ID}`,
      locationId: `${process.env.REACT_APP_SQUARE_LOCATION_ID}`,
      sandbox: true,
      autoBuild: false,
      applePay: {
        elementId: "sq-apple-pay",
      },
      googlePay: {
        elementId: "sq-google-pay",
      },
      callbacks: {
        methodsSupported: (methods: any, unsupportedReason: any): any => {
          if (isSafari) {
            if (methods.applePay) {
              setApplePay(methods.applePay);
            } else {
              console.log(unsupportedReason);
            }
          } else {
            if (methods.googlePay) {
              setGooglePay(methods.googlePay);
            } else {
              console.log(unsupportedReason);
            }
          }

          return;
        },
        createPaymentRequest: () => {
          return {
            requestShippingAddress: false,
            requestBillingInfo: false,
            currencyCode: "GBP",
            countryCode: "GB",
            total: {
              label: "Total",
              amount: `${paymentValuePerUnit}`,
              pending: false,
            },
            lineItems: [
              {
                label: `${categoryUnit}`,
                amount: `${paymentValuePerUnit}`,
                pending: false,
              },
            ],
          };
        },
        cardNonceResponseReceived: (errors: any, nonce: string) => {
          if (errors) {
            // Log errors from nonce generation to the Javascript console
            console.log("Encountered errors:");
            errors.forEach(function (error: any) {
              console.log("  " + error.message);
            });

            return;
          }
          handleNonce({
            unitId,
            nonce,
            amount: paymentValuePerUnit,
            currency: "GBP",
            selectionRefs: [userSelection],
            specialButtonsPressed: [userSpecialButton],
            paymentProvider: isSafari ? "APPLE_PAY" : "GOOGLE_PAY",
            paymentProcessor: "SQUARE",
          });
          setShowLoading(true);
        },
        shippingContactChanged: function (shippingContact: any, done: any) {
          var valid = true;
          var shippingErrors = { postalCode: "", addressLines: "" };

          if (!shippingContact.postalCode) {
            shippingErrors.postalCode = "postal code is required";
            valid = false;
          }
          if (!shippingContact.addressLines) {
            shippingErrors.addressLines = "address lines are required";
            valid = false;
          }

          if (!valid) {
            done({ shippingContactErrors: shippingErrors });
            return;
          }

          // Update total, lineItems, and shippingOptions for Canadian address.
          if (shippingContact.country === "GB") {
            done({
              total: {
                label: "MERCHANT NAME Test",
                amount: `${paymentValuePerUnit}`,
                pending: false,
              },
              // Note: lineItems REPLACES the set of the line items in the PaymentRequest
              lineItems: [
                {
                  label: "Tax Test",
                  amount: `${paymentValuePerUnit}`,
                  pending: false,
                },
              ],
            });
            return;
          }
          // No changes are necessary.
          done();
        },
      },
    };

    setLocalPaymentForm(() => {
      // @ts-ignore
      const localPaymentForm = new paymentForm(config);
      localPaymentForm.build();
      return localPaymentForm;
    });

    if (transactionPaymentStatusDetails.transactionStatus) {
      setShowLoading(false);
    }
  }, [
    paymentForm,
    paymentValuePerUnit,
    categoryUnit,
    unitId,
    userSelection,
    userSpecialButton,
    googlePay,
    transactionPaymentStatusDetails,
    handleNonce,
    showLoading,
  ]);

  return (
    <div>
      <StyledPaymentForm
        style={{
          display:
            transactionPaymentStatusDetails.transactionStatus === "" &&
            !showLoading
              ? "inherit"
              : "none",
        }}
      >
        <div className="container">
          <div id="form-container">
            <div id="sq-walletbox">
              <button
                style={{
                  display: applePay ? "inherit" : "none",
                  backgroundImage: `url(${ApplePayIcon})`,
                }}
                className="wallet-button apple-pay-button apple-pay-button-white"
                id="sq-apple-pay"
              />
              <button
                style={{ display: googlePay ? "inherit" : "none" }}
                className="wallet-button google-pay-button"
                id="sq-google-pay"
              />
            </div>
          </div>
        </div>
      </StyledPaymentForm>
      {showLoading && <LoadingComponent displayLoadingMessage={false} />}
      {transactionPaymentStatusDetails.transactionStatus !== "" && (
        <MessageNotification
          messageType={transactionPaymentStatusDetails.transactionStatus}
          messageTitle={transactionPaymentStatusDetails.transactionStatusTitle}
          messageBody={transactionPaymentStatusDetails.transactionStatusBody}
        />
      )}
    </div>
  );
};

export default PaymentForm;
