import styled from "styled-components";
import media from "../../../assets/styling/payme-media-queries";
import PaymeTheme from "../../../assets/styling/payme-theme";

const StyledFooter = styled.div`
  text-align: center;
  background-color: ${PaymeTheme.footerBackground};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 4.25rem;
  span {
    font-size: ${PaymeTheme.fontSizeRemSmall};
    color: ${PaymeTheme.colorWhite};
  }
  svg {
    width: 100%;
    height: auto;
  }

  ${media.desktopMedium} {
    margin: 0;
  }
`;

export { StyledFooter };
