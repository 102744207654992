// useFruitMachineFlow.ts
import { useCallback, useEffect, useState } from "react";
import { PaymentDetails, TransactionPaymentStatus } from "../../@types";
import { DashboardOption } from "../../components/Dashboard";

export enum FruitFlow {
  DASHBOARD = "Dashboard",
  POOL_TABLE = "Pool",
  PAYMENT = "Payment",
}

const SUCCESS_SCREEN_DURATION = 3000;

interface UseFruitMachineFlowProps {
  unitDetailsType: string;
  unitId: string;
  transactionStatus?: TransactionPaymentStatus | null;
  paymentJobReference?: string | null;
  winnings?: number | null;
  transactionAmount?: number;
  handleNonce: Function;
  onCollectWinnings: Function;
  onFlowComplete: Function;
}

export const useFruitMachineFlow = ({
  unitDetailsType,
  unitId,
  transactionStatus,
  paymentJobReference,
  winnings,
  transactionAmount,
  handleNonce,
  onCollectWinnings,
  onFlowComplete,
}: UseFruitMachineFlowProps) => {
  const isDemoUnit = useCallback(
    () =>
      (unitDetailsType === "AMUSE_NON_GAMB" ||
        unitDetailsType === "AMUSE_GAMB") &&
      unitId === "disabled",
    [unitDetailsType, unitId]
  );

  const [balance, setBalance] = useState(50.0);
  const [flowStep, setFlowStep] = useState(
    isDemoUnit() ? FruitFlow.DASHBOARD : FruitFlow.POOL_TABLE
  );

  const shouldShowDemoDashboard = () => flowStep === FruitFlow.DASHBOARD;
  const shouldShowPoolTableConnection = () => flowStep === FruitFlow.POOL_TABLE;
  const shouldShowPaymentScreen = () => flowStep === FruitFlow.PAYMENT;

  const handleDashboardClick = (navigation: string) => {
    switch (navigation) {
      case DashboardOption.WHERE_TO_BUY:
        setFlowStep(FruitFlow.POOL_TABLE);
        break;
      case DashboardOption.WINNINGS:
        updateBalance(winnings ? balance + winnings : balance);
        if (winnings) {
          onCollectWinnings();
        }
        break;
      case DashboardOption.LOYALTY:
        updateBalance(50.0);
        break;
    }
  };

  const handlePaymentDetails = (nonce: PaymentDetails) => {
    handleNonce(nonce);
  };

  useEffect(() => {
    switch (transactionStatus) {
      case "warning":
        setFlowStep(FruitFlow.PAYMENT);
        break;
      case "denied":
        setFlowStep(FruitFlow.POOL_TABLE);
        break;
      case "success":
        setFlowStep(FruitFlow.POOL_TABLE);
        setTimeout(() => {
          isDemoUnit()
            ? setFlowStep(FruitFlow.DASHBOARD)
            : setFlowStep(FruitFlow.POOL_TABLE);
          // Commented because screen should stay with Thank You title
          // onFlowComplete();
        }, SUCCESS_SCREEN_DURATION);
        break;
      case "timeout":
        setFlowStep(FruitFlow.POOL_TABLE);
        break;
      default:
        isDemoUnit()
          ? setFlowStep(FruitFlow.DASHBOARD)
          : setFlowStep(FruitFlow.POOL_TABLE);
        break;
    }
  }, [transactionStatus, paymentJobReference, isDemoUnit]);

  /**
   * Initializes the persisted balance in order to allow for a continuous demo flow
   *
   * The operations made on the balance are persisted in localStorage and as such, they are read from there
   */
  useEffect(() => {
    const savedBalance = localStorage.getItem("balance");
    if (savedBalance) {
      setBalance(parseFloat(savedBalance));
    } else {
      localStorage.setItem("balance", "50.00");
    }
  }, []);

  useEffect(() => {
    if (transactionAmount) {
      updateBalance(balance - transactionAmount);
    }
  }, [transactionAmount]);

  /**
   * Updates the balance and also persists it to localStorage
   * @param newBalance the new balance amount
   */
  const updateBalance = (newBalance: number) => {
    setBalance(newBalance);
    localStorage.setItem("balance", newBalance.toFixed(2));
  };

  return {
    balance,
    flowStep,
    isDemoUnit,
    shouldShowDemoDashboard,
    shouldShowPoolTableConnection,
    shouldShowPaymentScreen,
    handleDashboardClick,
    handlePaymentDetails,
  };
};
