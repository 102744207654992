import styled from "styled-components";
import PaymeTheme from "../../../assets/styling/payme-theme";

const PaymentStyles = styled.div((props) => {
  const buttonHeight = 70; //px

  return `
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items:center;

  .payment {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

    .title {

    }

    .amount {
      font-size: 12vmin;
      font-weight: bold;
      max-width: 70%;
    }

    .button {
      width: 70%;
      height: ${buttonHeight}px;
      background-color: #1A1A1A;
      color: white;
      font-size: 1.5rem;
      border-radius: 10px;
      color: ${PaymeTheme.colorWhite};
      font-size: ${PaymeTheme.fontSizeRemH5};
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 3.5rem;
      margin: 0.625rem auto;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      padding: 0;
      border: none;

      &:disabled {
        background-color: ${PaymeTheme.lightGrey};
        color: ${PaymeTheme.colorBlack};
        border-color: ${PaymeTheme.lightGrey};
      }

      &:focus {
        outline: none;
      }

    &.live {
    pointer-events: none;
      margin-bottom: 5rem;
    }

    &.failed {
      width: 70%;
      background-color: #DA1B62;
      color: white;
    }

    &.success {
      width: 70%;
      margin-bottom: 5rem;
      background-color: #0B930A;
      color: white;
    }


    }


  }

`;
});

export { PaymentStyles };
