import { FC, useContext, useEffect } from "react";
import { TransactionStatusDetails } from "../../Containers/UnitsContainer";
import {
  TransactionPaymentStatusDetails,
  UnitDetailsInterface,
} from "../../../@types";
import { hasValidFlowStatus } from "../../../utils/unitUtils";
import { StyledVivaPayment } from "./styles";

interface Props {
  paymentLink: string;
  paymentValue: string;
  unitDetails: UnitDetailsInterface;
  demo: boolean;
  onClickPayBtnHandler: () => void;
}

const VivaPayment: FC<Props> = ({
  paymentLink,
  paymentValue,
  unitDetails,
  demo,
  onClickPayBtnHandler,
}) => {
  const transactionPaymentStatusDetails =
    useContext<TransactionPaymentStatusDetails>(TransactionStatusDetails);

  const onClickHandler = () => {
    onClickPayBtnHandler();
  };

  /**
   * When the payment link is received from the backend, the UI client redirects to the payment link
   *
   * The demo logic is only here to disable the redirection
   */
  useEffect(() => {
    if (paymentLink !== "") {
      const url = new URL(paymentLink);
      const storageKey = url.searchParams.get("ref");

      if (storageKey) {
        const storageData = {
          [storageKey]: {
            photoUrl: unitDetails.photoUrl,
            hostTypeName: unitDetails.hostType.name,
          },
        };

        // Save to localStorage
        localStorage.setItem("unitDetailsStorage", JSON.stringify(storageData));
      }
      window.location.href = paymentLink;
    }
  }, [paymentLink]);

  return (
    <div>
      {transactionPaymentStatusDetails.transactionStatus === "" &&
        paymentLink === "" &&
        hasValidFlowStatus(unitDetails.flowStatus) && (
          <StyledVivaPayment>
            <button
              className="viva-button"
              onClick={onClickHandler}
              disabled={paymentValue === ""}
            >
              Pay
            </button>
          </StyledVivaPayment>
        )}
    </div>
  );
};

export default VivaPayment;
