import React from "react";
import VivaResponse from "./VivaResponse";
import {
  SUCCESS_MESSAGE_BODY,
  SUCCESS_MESSAGE_TITLE,
} from "../../../../constants";

const VivaSuccess: React.FC = () => {
  return (
    <VivaResponse
      messageType="success"
      messageBody={SUCCESS_MESSAGE_BODY}
      messageTitle={SUCCESS_MESSAGE_TITLE}
    />
  );
};

export default VivaSuccess;
