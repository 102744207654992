import React, { FC, useContext, useEffect } from "react";
import { StyledCashFlowPayment } from "./styles";
import MessageNotification from "../../common/Message";
import { TransactionStatusDetails } from "../../Containers/UnitsContainer";
import {
  TransactionPaymentStatusDetails,
  UnitDetailsInterface,
} from "../../../@types";
import { useMessagesRetriever } from "../../../hooks/generalHooks/useMessagesOnSocketStatus";
import LoadingComponent from "../../common/Loading";
import { hasValidFlowStatus } from "../../../utils/unitUtils";

interface Props {
  paymentLink: string;
  onClickPayBtnHandler: () => void;
  paymentValue: string;
  unitDetails: UnitDetailsInterface;
  demo: boolean;
}

const CashFlowPayment: FC<Props> = ({
  paymentLink,
  onClickPayBtnHandler,
  paymentValue,
  unitDetails,
  demo,
}) => {
  const transactionPaymentStatusDetails =
    useContext<TransactionPaymentStatusDetails>(TransactionStatusDetails);
  const [messageType, messageTitle, messageBody] =
    useMessagesRetriever(unitDetails);

  const onClickHandler = () => {
    onClickPayBtnHandler();
  };

  /**
   * When the payment link is received from the backend, the UI client redirects to the payment link
   *
   * The demo logic is only here to disable the redirection
   */
  useEffect(() => {
    if (paymentLink !== "" && !demo) {
      window.location.href = paymentLink;
    }
  }, [paymentLink, demo]);

  return (
    <div>
      {transactionPaymentStatusDetails.transactionStatus === "" &&
        paymentLink === "" &&
        hasValidFlowStatus(unitDetails.flowStatus) && (
          <StyledCashFlowPayment>
            <button
              className="cashflow-button"
              onClick={onClickHandler}
              disabled={paymentValue === ""}
            >
              Pay
            </button>
          </StyledCashFlowPayment>
        )}

      {!(
        transactionPaymentStatusDetails.transactionStatus === "" &&
        paymentLink === "" &&
        hasValidFlowStatus(unitDetails.flowStatus)
      ) && (
        <div className="loading-wrapper">
          <LoadingComponent displayLoadingMessage={false} />
        </div>
      )}
      {hasValidFlowStatus(unitDetails.flowStatus) && (
        <MessageNotification
          messageType={messageType as string}
          messageTitle={messageTitle as string}
          messageBody={messageBody as string}
        />
      )}
      {transactionPaymentStatusDetails.transactionStatus !== "" && (
        <MessageNotification
          messageType={transactionPaymentStatusDetails.transactionStatus}
          messageTitle={transactionPaymentStatusDetails.transactionStatusTitle}
          messageBody={transactionPaymentStatusDetails.transactionStatusBody}
        />
      )}
    </div>
  );
};

export default CashFlowPayment;
