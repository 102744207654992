import { FC } from "react";

export interface VendingSelectionProps {
  selectionStatus?: string;
  paymentValuePerUnit: number;
  userSpecialButton: string;
  currencySymbol: string;
  userSelection?: string;
}
export const VendingSelection: FC<VendingSelectionProps> = ({
  selectionStatus,
  paymentValuePerUnit,
  userSelection,
  userSpecialButton,
  currencySymbol,
}) => (
  <div className="selection-wrapper">
    {selectionStatus && (
      <div className="selection-status">{selectionStatus}</div>
    )}
    {!!paymentValuePerUnit && userSelection && (
      <div className="selection-values">
        {userSelection}
        {userSpecialButton !== "" && <span>, {userSpecialButton} </span>}
        <span>
          {paymentValuePerUnit !== -1 &&
            currencySymbol &&
            `= ${currencySymbol}${paymentValuePerUnit}`}
        </span>
      </div>
    )}
  </div>
);
