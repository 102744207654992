import { FC } from "react";
import { StyledFooter } from "./styles";
import { LogoSvgComponent } from "./FooterSvgLogo";

const Footer: FC = () => {
  return (
    <StyledFooter>
      <span>Powered by</span>
      <LogoSvgComponent />
    </StyledFooter>
  );
};

export default Footer;
