import React from "react";
import { StyledUnitDetails } from "../../../UnitDetails/styles";
import MessageNotification from "../../../common/Message";
import { StyledVivaResponse } from "../styles";

interface VivaResponseProps {
  messageType: string;
  messageTitle: string;
  messageBody: string;
}

const VivaResponse: React.FC<VivaResponseProps> = ({
  messageType,
  messageTitle,
  messageBody,
}) => {
  // Use window.location.search instead of href for URLSearchParams
  const urlParams = new URLSearchParams(window.location.search);
  const storageKey = urlParams.get("s");

  // Handle the case where storageKey is null or the item doesn't exist in localStorage
  const storedData = storageKey
    ? localStorage.getItem("unitDetailsStorage")
    : null;

  // Add type safety for the parsed JSON data
  const unitDetailsFromStorage = storedData
    ? (JSON.parse(storedData) as Record<string, any>)
    : null;

  // Check if unitDetailsFromStorage exists and has the key before accessing
  const unitDetails =
    unitDetailsFromStorage && storageKey
      ? unitDetailsFromStorage[storageKey]
      : null;

  const messageBodyWithOrderCode =
    messageType === "error"
      ? `${messageBody} Order code: ${storageKey}`
      : messageBody;

  return (
    <StyledUnitDetails>
      <div className="image-unit-wrapper image-viva-response">
        <img src={unitDetails?.photoUrl || undefined} alt="unit" />
      </div>
      <StyledVivaResponse>
        <div className="unit-category-name">{unitDetails.hostTypeName}</div>
        <div className="unit-payment-value">
          {unitDetails.paymentValue} {unitDetails.currency}
        </div>
      </StyledVivaResponse>
      <MessageNotification
        messageType={messageType}
        messageTitle={messageTitle}
        messageBody={messageBodyWithOrderCode}
      />
    </StyledUnitDetails>
  );
};

export default VivaResponse;
