import React from "react";
import { createRoot } from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import Main from "./components/Containers/Main";
import dotenv from "dotenv";

dotenv.config();

// Get the root element
const rootElement = document.getElementById("root");

// Check if the element exists
if (!rootElement) {
  throw new Error("Root element not found. Cannot mount React app.");
}

// Create a root with the non-null assertion
const root = createRoot(rootElement);

// Render your app
root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
