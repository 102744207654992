import styled from "styled-components";
import media from "../../assets/styling/payme-media-queries";
import PaymeTheme from "../../assets/styling/payme-theme";

const StyledUnitDetails = styled.div`
  display: flex;
  text-align: center;
  position: relative;
  flex-direction: column;
  background-color: ${PaymeTheme.footerBackground};

  .image-unit-wrapper {
    width: 100%;
    height: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    ${media.tablet} {
      max-width: 100%;
      height: 60vh;

      img {
        object-fit: contain;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease;
  }

  .label-error {
    color: ${PaymeTheme.errorColor};
  }

  .credit-value-input {
    color: ${PaymeTheme.colorBlack};
    height: 5vh;
    max-height: 40px;
    min-height: 25px;
    border-radius: 4px;
    background-color: ${PaymeTheme.colorWhite};
    font-family: "Exo 2" !important;
    font-size: ${PaymeTheme.fontSizeRemNormal}!important;
    min-width: 250px;
    border: ${PaymeTheme.floatColor} solid 1px;
    padding: 8px;
    &:focus-visible {
      outline: none;
    }
  }

  .unit-content-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: ${PaymeTheme.paddingRemLg} auto 0;
  }

  .unit-category-name {
    font-size: ${PaymeTheme.fontSizeRemH5};
    color: ${PaymeTheme.floatColor};
  }

  .unit-payment-value {
    font-size: ${PaymeTheme.fontSizeRemH1};
  }

  .selection-wrapper {
    font-size: ${PaymeTheme.fontSizeRemH3};
    max-width: 75%;
    margin: auto;
  }

  .change-price-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -${PaymeTheme.paddingRemLg};
    .change-price {
      margin: auto;
      display: flex;
      align-items: center;
      flex-direction: column;

      .change-price-description {
        font-size: ${PaymeTheme.fontSizeRemH6};
      }

      .change-price-functionality {
        .MuiIconButton-root {
          padding: 0;
        }
        display: flex;
        align-items: center;
        svg {
          width: 15vw;
          height: 15vh;
          max-width: 115px;
          max-height: 60px;
          fill: ${PaymeTheme.colorWhite};
        }
        div {
          border: 2px solid ${PaymeTheme.floatColor};
          width: 35vw;
          max-width: 130px;
          height: 5vh;
          border-radius: 10px;
          padding: 0.2rem 0.7rem;
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            font-size: ${PaymeTheme.fontSizeRemH4};
          }
        }
      }
    }
  }

  ${media.desktopMedium} {
    margin: 0;
  }
`;

export { StyledUnitDetails };
