// Extracted component for single value payment info

import { FC } from "react";

interface SingleValuePaymentProps {
  defaultTransactionAmount: number;

  currencySymbol: string;
  addDecimals: (value: string) => string;
}
export const SingleValuePayment: FC<SingleValuePaymentProps> = ({
  currencySymbol,
  defaultTransactionAmount,
  addDecimals,
}) => (
  <div className="unit-payment-value">
    {currencySymbol}
    {!!defaultTransactionAmount &&
      addDecimals(defaultTransactionAmount.toString())}{" "}
    play
  </div>
);
