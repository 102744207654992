import styled from "styled-components";
import media from "../../../assets/styling/payme-media-queries";
import PaymeTheme from "../../../assets/styling/payme-theme";

const StyledMessage = styled.div`
  text-align: center;
  padding: ${PaymeTheme.paddingRem};
  min-height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.warning,
  &.error {
    background-color: ${PaymeTheme.warningColor};
  }

  &.denied,
  &.unavailable,
  &.timeout {
    background-color: ${PaymeTheme.colorPurple};
  }

  &.success {
    background-color: ${PaymeTheme.successColor};
  }

  .message-title {
    font-weight: ${PaymeTheme.fontBold};
    font-size: ${PaymeTheme.fontSizeRemLg};
    padding-bottom: ${(props) =>
      props.theme.hasMessageBody ? PaymeTheme.paddingRemMd : 0};
  }

  .message-body {
    font-size: ${PaymeTheme.fontSizeRemLg};
    max-width: 13.625rem;
    margin: auto;
  }

  ${media.desktopMedium} {
    margin: 0;
  }
`;

export { StyledMessage };
