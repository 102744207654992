import { IconButton } from "@mui/material";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { FC } from "react";

interface PriceChangerProps {
  priceValue: number;
  inputError: string;
  enableChangeValueButtons: boolean;
  changeValue: (value: number) => void;
  addDecimals: (value: string) => string;
}
export const PriceChanger: FC<PriceChangerProps> = ({
  priceValue,
  inputError,
  enableChangeValueButtons,
  changeValue,
  addDecimals,
}) => (
  <div className="change-price-wrapper">
    <div className="change-price">
      <div className="change-price-functionality">
        <IconButton
          onClick={() => enableChangeValueButtons && changeValue(-0.5)}
        >
          <RemoveRoundedIcon htmlColor={"#0090DF"} />
        </IconButton>
        <div>
          <span>£</span>
          <span>{!!priceValue && addDecimals(priceValue.toString())}</span>
        </div>
        <IconButton
          onClick={() => enableChangeValueButtons && changeValue(0.5)}
        >
          <AddRoundedIcon htmlColor={"#0090DF"} />
        </IconButton>
      </div>
    </div>
    {inputError && <div className="label-error">{inputError}</div>}
  </div>
);
